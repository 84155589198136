header {
  padding: 14px 0px;
  border-bottom: 1px solid #f1d513;
  position: sticky;
  top: 0;
  background: #000;
  z-index: 99;
}
.headerLogo img {
  width: 100%;
  max-width: 120px;
}
.userSec img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
