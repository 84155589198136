.BannData h1 {
  text-transform: uppercase;
  font-family: "Barlow", sans-serif;
  font-size: 48px;
  font-weight: 700;
  color: #f1d513;
}

.BannData p {
  font-size: 16px;
  color: #d7d7d7;
}
.HomeTabs .nav-tabs button {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  background: #1c1c1d;
  backdrop-filter: blur(25.65199851989746px);
  color: #fff;
  font-family: "Avant semibold";
  font-size: 16px;
  min-width: 184px;
}
.HomeTabs ul {
  border: none;
  display: flex;
  gap: 12px;
}

.HomeTabs .nav-link:hover,
.HomeTabs .nav-link.active {
  color: #000;
  border-radius: 5px;
  background: #f5e003 !important;
  backdrop-filter: blur(25.65199851989746px);
}
.greyCard {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(74, 74, 74, 0.21);
  backdrop-filter: blur(25.65199851989746px);
  padding: 16px;
  color: #fff;
  height: 100%;
}

.tabContent .greyCard h1 {
  font-size: 40px;
  font-weight: 700;
}
.tabContent .greyCard hr {
  background: #ffffff24;
}
.tabContent .greyCard span {
  font-family: "Avant semibold";
  font-size: 14px;
}
.tabContent .greyCard p {
  font-family: "Avant semibold";
}
.tabContent .greyCard svg {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100% !important;
  }
}
.chartBG {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(1, 1, 1, 0.21);
  box-shadow: 0px 4px 29.9px 0px rgba(255, 255, 255, 0.13) inset;
  backdrop-filter: blur(25.65199851989746px);
  padding: 20px;
}
.yellow {
  color: #f1d513;
}
h2.ChartTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
  border-left: 5px solid #f1d513;
  padding-left: 20px;
  padding-bottom: 20px;
}
.chartTitleData p {
  color: #fff;
}
.greyCard .yellow {
  display: inline-block;
}

.SmallTitle p {
  color: #b4b4b4;
  font-size: 11px;
  margin: 0;
}
.SmallTitle h2 {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}
.SmallTitle {
  display: flex;
  gap: 20px;
  align-items: center;
}
.insightCard i {
  position: absolute;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
}
.insightCard {
  position: relative;
}
.mainChart img {
  width: 100%;
}

.TabFilters select {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  background: #1c1c1d;
  backdrop-filter: blur(25.65199851989746px);
  color: #fff;
  font-family: "Avant semibold";
  font-size: 16px;
  padding: 10px 30px;
  cursor: pointer;
}
.TabFilters {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: -43px;
}

.p-10-0 {
  padding: 0 10% !important;
}

.p-5-0 {
  padding: 0 5% !important;
}
canvas {
  border-radius: 10px;
}

.kpCard span {
  font-size: 16px;
}
.kpCard h1 {
  font-family: "Avant bold";
}
.kpCard p {
  font-size: 18px;
  margin: 0;
}
.div-block p {
  color: #fff;
}
.beerIndus {
  background: rgba(25, 25, 25, 0.28);
  backdrop-filter: blur(22.910192489624023px);
  border-radius: 10.473px;
  border: 1px solid rgba(22, 156, 232, 0.68);
}

.dis_f_c {
  display: flex;
  align-items: center;
}

.r_90 {
  writing-mode: tb-rl;
  -webkit-transform: rotate(180deg);
  width: 30px;
}

.text-block-2 {
  font-size: 16px;
  color: #fff;
  text-align: center;
}

.font_26 {
  font-size: 26px;
}
.line {
  background: linear-gradient(90deg, #101010 0%, #fafafa 51%, #101010 100%);
  height: 2px;
  opacity: 0.3;
  margin: 30px 0px;
}
.loginDivWrapper {
  position: fixed;
  height: 100vh;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}
.loginDiv {
  position: relative;
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(1, 1, 1, 0.21);
  box-shadow: 0px 4px 29.9px 0px rgba(255, 255, 255, 0.13) inset;
  -webkit-backdrop-filter: blur(25.65199851989746px);
  backdrop-filter: blur(25.65199851989746px);
  padding: 40px 20px;
  max-width: 343px;
  min-height: 386px;
  margin: 0px auto;
  color: #fff;
}
.loginDiv p {
  font-family: "Avant semibold";
  font-size: 14px;
  color: #919191;
}
.loginDiv h2 {
  font-size: 22px;
  font-family: "Avant semibold";
}

.loginButton img {
  margin-left: 6px;
}
.loginButton:hover {
  background: #fff !important;
}
.loginButton {
  border-radius: 50px;
  background: #f5e003;
  border: none;
  -webkit-backdrop-filter: blur(25.65199851989746px);
  backdrop-filter: blur(25.65199851989746px);
  color: #000;
  width: 180px;
  height: 40px;
  font-family: "Avant semibold";
}

.bootomLine {
  border-radius: 34px;
  background: #f5e003;
  width: 140px;
  height: 14px;
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
}
.proOff img {
  max-width: 100px;
}
.proOff {
  margin-top: 40px;
}

.f-20 {
  font-size: 20px !important;
}

.MobileTabDropdown select:focus {
  outline: 0;
}
.MobileTabDropdown select {
  cursor: pointer;
  font-family: "Avant semibold";
  border-radius: 2.639px;
  border: 0.528px solid #f5e003;
  background: #f5e003;
  box-shadow: 0px 2.111px 15.781px 0px rgba(255, 255, 255, 0.13) inset;
  backdrop-filter: blur(13.538871765136719px);
  padding: 10px 20px 10px 30px;
  color: #000;
}
.MobileTabDropdown label {
  color: #fff;
  font-size: 16px;
}
.MobileTabDropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
}
.selectWrap {
  position: relative;
}
.selectWrap img {
  position: absolute;
  display: none;
  z-index: 3;
  width: 100%;
  max-width: 15px;
  left: 10px;
  top: 11px;
}

.selectWrap select {
  /* background-color: #1c1c1d; */
  color: #000;
}

.selectWrap select > option {
  background-color: #1c1c1d;
  color: #fff;
}

.selectWrap select option:checked {
  background-color: #1c1c1d;
  color: #fff;
}

.ta-c {
  text-align: center;
}

.volumeChart {
  /* scrollbar-width: thin; */
  /* scrollbar-color: #888 #ccc; */
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  height: calc(100% + 20px);
}

.subVolumeChart {
  min-width: 1200px;
  width: 100%;
}
.footnote-container {
  text-align: right;
  margin-top: 50px;

}

.footnote {
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  color: #b3b3b3; /* Light grey for readability */
  background-color: #222222; /* Dark background to blend with the chart's theme */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444444; /* Subtle border for separation */
  width: fit-content;
  margin: 0 0 0 auto; /* Align element to the right within the container */
}

.footnote span {
  color: #ffc107; /* Accent yellow for highlighting key text */
}


*::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px !important; /* For vertical scrollbars */
  height: 5px !important; /* For horizontal scrollbars */
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px !important;
}

*::-webkit-scrollbar-track {
  background: #ccc !important;
  width: 5px !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #f1d513 !important;
  border-radius: 20px !important;
  border: 1px solid #f1d513 !important;
}
