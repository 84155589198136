footer{
  text-align: center;
  color: #fff;
  margin-top: 40px;
}
footer hr {
  width: 150px;
  margin: 8px auto;
}

footer p{
  font-size: 12px;
}
.source-text {
  font-family: 'YourFontFamily', sans-serif; /* Replace 'YourFontFamily' with the actual font family used */
  font-size: 16px;
  color: #cccccc;
  opacity: 1;
  text-align: right;
  padding-top: 10px;


}

