@font-face {
  font-family: "Avant light";
  src: url(./assets/fonts/Avantt-Light.ttf);
}

@font-face {
  font-family: "Avant regular";
  src: url(./assets/fonts/Avantt-Light.ttf);
}

@font-face {
  font-family: "Avant semibold";
  src: url(./assets/fonts/Avantt-SemiBold.ttf);
}

@font-face {
  font-family: "Avant bold";
  src: url(./assets/fonts/Avantt-Bold.ttf);
}

body {
  font-family: "Avant regular";
  background-image: url(./assets/images/bodyBG.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  /* border-bottom: 9px solid #f1d513; */
}

.country-item {
  white-space: nowrap;
  width: 123px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.d-flex {
  display: flex;
}

.text-center {
  text-align: center;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.label-text {
  color: #919191;
}

.f-10 {
  font-size: 10px;
}
/* 
.mobileViewCharts {
  position: relative;
  height: 560px;
  overflow: auto;
}

.vertical-lines {
  display: flex;
  text-align: center;
  justify-content: center;
  position: sticky;
  height: 100%;
  width: 100%;
  z-index: 999;
}

.vertical-lines > div {
  pointer-events: none;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
} */

/* 
.vertical-lines div div {
  width: 1px !important;
  height: 100%;
  border-left: .3px solid #473e3e;
  flex: none;
} */

.bold {
  font-weight: bold;
}
.p-10 {
  padding: 10px;
}

.mobileViewCharts {
  position: relative;
  height: 560px;
  overflow-y: scroll;
}

.vertical-lines {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 99;
}

.vertical-lines > div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.vertical-lines div:nth-child(2) {
  border-right: 1px dashed #090909;
}

.vertical-lines > div::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100vh; /* Extend the height beyond viewport */
  /* background-color: #473e3e; */
  border-left: 0.3px solid #454343;
}
