@media (min-width: 768px) {
  .MobileTabDropdown {
    display: none;
  }
}
@media (max-width: 767px) {
  .kpCard p {
    font-size: 16px !important;
    position: relative;
    top: 7px;
    left: 7px;
  }
  .kpCard span {
    font-size: 14px !important;
  }
  .kpCard svg {
    width: 27px;
    float: left;
  }
  .kpCard hr {
    margin: 6px 0;
  }
  .TabFilters {
    margin-bottom: 16px;
  }
  .TabFilters select {
    flex: 1;
  }
  .HomeTabs ul li button {
    width: 100%;
  }
  .HomeTabs ul li {
    flex: 1;
  }
  .greyCard {
    margin-bottom: 16px;
    height: auto !important;
    padding: 12px !important;
  }
  .tabContent .greyCard hr {
    margin: 6px 0 6px 0;
  }
  .tabContent .greyCard p {
    margin: 0;
    font-size: 13px;
    vertical-align: middle;
    display: inline-block;
    margin-left: 4px;
  }
  .tabContent .greyCard svg {
    width: 30px;
    height: 30px;
    float: left;
  }
  .tabContent .greyCard h1 {
    font-size: 32px;
  }
  .tabContent .greyCard span {
    font-size: 12px;
  }
  .BannData p {
    font-size: 12px;
  }

  .BannData h1 {
    font-size: 28px !important;
    margin: 0 !important;
  }
  .BannData {
    max-width: 330px;
    margin: 0 auto;
  }
  .TabFilters {
    margin-bottom: 0px !important;
  }
  .HomeTabs ul {
    display: none !important;
  }
  .ChartTitle-sec3 {
    background-color: #1b1b1b !important;
    font-size: 18px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
}

.title-text {
  color: #a8a8a8;
  font-size: 20px;
}
